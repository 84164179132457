import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
//import Confetti from 'react-confetti';
import * as Sentry from "@sentry/react";
import LoggingService from "services/logging";

import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/MathsMaster/Player";
import Tutorial from "components/MathsMaster/MathsTutorial";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/MathsMaster/MathsHostStyles.module.scss';

import logo from "images/Host/maths-master-shop-logo.png";
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import ButtonImg1 from "images/MathsMaster/button-1.png";
import ButtonImg2 from "images/MathsMaster/button-2.png";
import RaysImg from "images/MathsMaster/rays.png";
import Round1Img from "images/MathsMaster/round-1-text.png";
import Round2Img from "images/MathsMaster/round-2-text.png";
import Round3Img from "images/MathsMaster/round-3-text.png";
import WinnerBanner from "images/MathsMaster/winner-banner.png";
import WinnersTitle from "images/MathsMaster/winners-banner.png";
import ClockImg from "images/MathsMaster/clock.png";
import ClockHandImg from "images/MathsMaster/clock-hand.png";
import ClockBg from "images/MathsMaster/clock-bg.png";
import GoalBg from "images/MathsMaster/big-number-banner.png";
import NumbersBg from "images/MathsMaster/number-tile-group.png";

import ClickSFX from "audio/click.wav";
import ClockTickSFX from "audio/clock-ticking.mp3";
import CorrectSFX from "audio/correct.wav";
import ItemPopupSFX from "audio/item-popup.wav";
import RoundOverSFX from "audio/round-over.wav";
import SkipSFX from "audio/skip.wav";
import BGMusic from "audio/temp-bg-music.wav";
import Woosh from "audio/woosh.mp3";
import FanfareSFX from "audio/fanfare.wav";
//import RevealSFX from "audio/reveal.wav";
//import DrumRollSFX from "audio/drum-roll.wav";

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    Click: {
        import: ClickSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ClockTick: {
        import: ClockTickSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    Correct: {
        import: CorrectSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ItemPopup: {
        import: ItemPopupSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    RoundOver: {
        import: RoundOverSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Skip: {
        import: SkipSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Woosh: {
        import: Woosh,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Fanfare: {
        import: FanfareSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
};

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "maths_master";

export class MathsHost extends Component {
    static displayName = MathsHost.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            reconnectionToken: "",
            connected: false,
            //connected: true, //debug
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,
            logStreamId: "",

            gotLocationPing: true,
            connectionIssue: false,

            players: [],
            //players: [ //debug
            //    {
            //        name: "Player1",
            //        id: "1",
            //        avatar: 1,
            //        mathsData: {
            //            answer: "answer",
            //            score: 1,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            equationHistory: ["5 * 5 = 25", "25 * 6 = 300", "3 * 7 = 21", "300 + 21 = 321"],
            //            submittedNumber: 321
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        name: "Player2",
            //        id: "2",
            //        avatar: 2,
            //        mathsData: {
            //            answer: "answer",
            //            score: 1,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            equationHistory: []
            //        },
            //        showPlayer: true,
            //    },
            //    {
            //        name: "Player3",
            //        id: "3",
            //        avatar: 3,
            //        mathsData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            equationHistory: []
            //        },
            //        showPlayer: false,
            //    },
            //    {
            //        name: "Player4",
            //        id: "4",
            //        avatar: 4,
            //        mathsData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "Interest"]
            //        },
            //        showPlayer: false,
            //    },
            //    {
            //        "name": "Player5",
            //        "id": "5",
            //        "avatar": 5,
            //        mathsData: {
            //            answer: "answer",
            //            score: 1,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "Chocolate"]
            //        },
            //        showPlayer: false,
            //    },
            //    {
            //        "name": "WWWWWWWWWW",
            //        "id": "6",
            //        "avatar": 6,
            //        mathsData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "supermans"]
            //        },
            //        showPlayer: false,
            //    },
            //    {
            //        "name": "Pla",
            //        "id": "7",
            //        "avatar": 7,
            //        mathsData: {
            //            answer: "answer",
            //            score: 2,
            //            ready: false,
            //            showAnswer: false,
            //            showValidation: false,
            //            isCorrect: false,
            //            answerHistory: [
            //                "banana"]
            //        },
            //        showPlayer: false,
            //    },
            //],
            playersRequired: 2,
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,
            //showTutorial: true,
            skipTutorialCount: 0,

            timerText: 0,
            showTimer: false,
            //showTimer: true,

            showStartButtons: true,
            //showStartButtons: false,//Debug

            showPlayAgainButtons: false,

            showRoundNumber: false,
            showRoundNumberRays: false,
            roundNumber: 0,

            showNumberGoal: false, 
            //showNumberGoal: true, //Debug
            showNumbersSectionRays: false,
            //showNumbersSectionRays: true, //Debug
            showCurrentNumbers: false,
            //showCurrentNumbers: true, //Debug
            numberGoal: 999,
            currentNumbers: [],
            //currentNumbers: [1, 2, 3, 4, 50, 100],
            showNumbers:[],
            //showNumbers: [true, true, true, true, true, true], //Debug
            closestPlayerKey: "",
            //closestPlayerKey: "1", //Debug
            hideClosesPlayer: false,

            showWinnerTitle: false,
            showWinnerRays: false,
            showWinners: {},

            showEndGame: false,
            //showEndGame: true,

            //doConfetti: false,
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        //this.toggleMute(true, false);
        this.initAudio();
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic, true, 0.15);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio && audio.loaded) audio.loaded.play();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen === true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = () => {
        this.playAudio(audio.Click);
        this.setState({ showStartButtons: false });
        //this.state.room.send("begin_game", { skipTutorial: true, });
        this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
    }
    goToLobby = () => {
        this.playAudio(audio.Click);
        this.state.room.send("change_game", {});
    }
    signalNewGame = () => {
        this.playAudio(audio.Click);
        this.setState({ showPlayAgainButtons: false, });
        setTimeout(() => {
            this.state.room.send("maths_new_game", {});
        }, 1000);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    hideTimer() {
        this.setState({ showTimer: false, });

        //if (audio.Timer5SecondsClock.loaded) audio.Timer5SecondsClock.loaded.stop();
    }

    getRoundCountImg = () => {
        const roundImgs = [Round1Img, Round2Img, Round3Img];
        return roundImgs[this.state.roundNumber - 1];
    }

    shuffle(string) {
        return string.split('')
            .sort(() => 0.5 - Math.random())
            .join('');
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async doStartRound(message) {
        this.setState({
            currentNumbers: message.currentNumbers, showNumbers: message.currentNumbers.map(()=>false), numberGoal: message.numberGoal, showPlayers: true,
            roundNumber: message.roundNumber, closestPlayerKey: "", infoBoxText: "Get as close to the goal using these numbers!"
        });
        await this.sleep(750);
        this.setState({ showRoundNumberRays: true });
        await this.sleep(500);
        this.playAudio(audio.Woosh);
        this.setState({ showRoundNumber: true, });
        await this.sleep(5000);
        this.setState({ showRoundNumber: false, });
        this.playAudio(audio.Woosh);
        await this.sleep(500);
        this.setState({ showRoundNumberRays: false, });
        await this.sleep(2000);
        this.playAudio(audio.ItemPopup);
        this.setState({ showInfoBox: true, });
        await this.sleep(1000);
        this.setState({ showNumbersSectionRays: true });
        await this.sleep(500);
        this.setState({ showCurrentNumbers: true, });
        let showNumbers = [...this.state.showNumbers];
        for (let i = 0; i < this.state.showNumbers.length; i++) {
            showNumbers[i] = true;
            this.setState({ showNumbers });
            this.playAudio(audio.ItemPopup);
            await this.sleep(100);
        }
        await this.sleep(1000);
        //this.playAudio(audio.Skip);
        this.setState({ showNumberGoal: true, });
        await this.sleep(500);
        this.state.room.send("round_started", {});
    }

    async doShowAnswers(closestPlayerKey) {
        this.setState({ showTimer: false, showInfoBox: false});
        await this.sleep(1000);
        this.playAudio(audio.ItemPopup);
        this.setState({ infoBoxText: "Lets see how everyone did?", showInfoBox: true });
        await this.sleep(500);
        let statePlayers = [...this.state.players];
        for (let i = 0; i < statePlayers.length; i++) {
            if (i % 2 === 0) {
                await this.sleep(500);
                let statePlayer = { ...statePlayers[i] };
                statePlayer.mathsData.showAnswer = true;
                statePlayers[i] = statePlayer;
                this.setState({ players: statePlayers });
                this.playAudio(audio.ItemPopup);
            }
        }
        this.sleep(500);

        for (let i = 0; i < statePlayers.length; i++) {
            if (i % 2 === 1) {
                await this.sleep(500);
                let statePlayer = { ...statePlayers[i] };
                statePlayer.mathsData.showAnswer = true;
                statePlayers[i] = statePlayer;
                this.setState({ players: statePlayers });
                this.playAudio(audio.ItemPopup);
            }
        }
        await this.sleep(2500);
        for (let i = 0; i < statePlayers.length; i++) {
            let statePlayer = { ...statePlayers[i] };
            if (statePlayer.mathsData.hasEarnedPoints === true) {
                statePlayer.mathsData.showValidation = true;
                statePlayers[i] = statePlayer;
            }
        }
        this.setState({ players: statePlayers, closestPlayerKey: closestPlayerKey, hideClosesPlayer :false});

        await this.sleep(4000);
        for (let i = 0; i < statePlayers.length; i++) {
            let statePlayer = { ...statePlayers[i] };
            statePlayer.mathsData.showAnswer = false;
            statePlayers[i] = statePlayer;
        }
        this.setState({ players: statePlayers, });
        await this.sleep(1500);

        this.state.room.send("validate_answers", {});
    }

    async doEndRound() {
        await this.sleep(3000);
        let showNumbers = [...this.state.showNumbers];
        for (let i = 0; i < this.state.showNumbers.length; i++) {
            showNumbers[i] = false;
            this.setState({ showNumbers });
            await this.sleep(100);
        }
        await this.sleep(500);
        this.setState({ showNumbersSectionRays: false, showInfoBox: false, showCurrentNumbers: false, showNumberGoal: false, hideClosesPlayer: true});

        let statePlayers = [...this.state.players];
        for (let i = 0; i < statePlayers.length; i++) {
            let statePlayer = { ...statePlayers[i] };
            statePlayer.mathsData.showValidation = false;
            statePlayer.mathsData.showAnswer = false;
            statePlayers[i] = statePlayer;
        }
        this.setState({ players: statePlayers });

        await this.sleep(1000);
        this.state.room.send("start_round");
    }

    async doGameOver() {
        await this.sleep(2000);
        this.playAudio(audio.ItemPopup);
        this.setState({ showWinnerRays: true, showWinnerTitle: true, showPlayers: false, });
        await this.sleep(1000);
        let winners = this.getWinners();
        let showWinners = {};
        winners.forEach((x, i) => {
            showWinners[x.id] = true;
        });
        this.playAudio(audio.Fanfare);
        this.setState({ showWinners });
        await this.sleep(5000);
        this.setState({ showWinners: {}, showWinnerRays: false, showWinnerTitle: false, });
        await this.sleep(2000);
        this.state.room.send("reached_end", {});
        //this.playAudio(audio.Skip);
        this.setState({ showPlayAgainButtons: true, });
    }

    showClosestAnswer() {
        const player = this.state.players.find(p => p.id === this.state.closestPlayerKey);
        //player.mathsData.equationHistory = ["5 * 5 = 25", "25 * 6 = 300", "3 * 7 = 21", "300 + 21 = 321"]
        //player.mathsData.submittedNumber = 321;
        console.log("Player : ", player);
        const answerHistory = player.mathsData.equationHistory.map((value, index) => {
            return <p className={styles.equation} style={{ "--delay": (index/3)+"s" }}>{value}</p>;
        });
        return <React.Fragment>
            < div className={`${styles.potato} ${this.state.hideClosesPlayer && styles.hide}`}>
                <div className={`${styles.nameBox}`}>
                    <div className={`${styles.name}`}>
                        {player.name}
                    </div>
                </div>
                <Lottie
                    options={getAvatarById(player.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div >
            <div className={`${styles.answer} ${!this.state.hideClosesPlayer && styles.show}`} >
                {answerHistory}
                <p className={`${styles.playerAnswer} ${styles.equation}`} style={{ "--delay": (answerHistory.length / 3) + "s" }} >{player.mathsData.submittedNumber}</p>
            </div>
        </React.Fragment>
    }

    getWinners() {
        let statePlayers = [...this.state.players];
        let winners = [];
        let highestScore = 0;
        for (let i = 0; i < statePlayers.length; i++) {
            if (statePlayers[i].mathsData.score > highestScore) {
                highestScore = statePlayers[i].mathsData.score;
            }
        }

        for (let i = 0; i < statePlayers.length; i++) {
            if (statePlayers[i].mathsData.score === highestScore) {
                winners.push(statePlayers[i]);
            }
        }

        return winners;
    }

    getSortedPlayersByScore() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.mathsData.score - a.mathsData.score;
        });
        let lastIndex = 1;
        return <React.Fragment>
            {
                statePlayers.map((x, index, arr) => {
                    if (index > 0 && x.mathsData.score < arr[index - 1].mathsData.score) lastIndex++;
                    return <tr className={`${styles.tableRow} ${styles.player}`}>
                        <div className={styles.position}>{lastIndex}</div>
                        <div className={styles.name}>{x.name}</div>
                        <div className={styles.score}>{x.mathsData.score}<span className={styles.smaller}>pts</span></div>
                        <div className={styles.potato}>
                            <Lottie
                                options={getAvatarById(x.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </tr>
                })
            }
        </React.Fragment>
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at Word Wheel");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            //const roomId = this.getQueryStringValue("roomId");
            //const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            //console.log(roomId);

            if (this.state.connected === false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id !== room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });
                        this.setState({ roomState: state, logStreamId: state.uniqueId });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Maths Master, Reconnection Token: ${room.reconnectionToken}`);

                        // this casues everyone to go back to lobby if the host has refreshed their page mid game
                        if (state.mathsData.gameState === GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, });
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);

                        const changes = ["connected", "votedSkip"];
                        changes.forEach(change => {
                            player.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                statePlayer[change] = value;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });

                        const mathsPlayerChanges = ["score", "answer", "ready", "submittedNumber", "hasEarnedPoints", "earnedPoints"];

                        mathsPlayerChanges.forEach(change => {
                            player.mathsData.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                statePlayer.mathsData[change] = value;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });

                        const mathsPlayerArrayChanges = ["equationHistory", "playerNumbers", "initialPlayerNumbers"];

                        mathsPlayerArrayChanges.forEach(arrayName => {
                            player.mathsData[arrayName].onChange((change, index) => {
                                console.log("!!! Begin Array change for: ", arrayName);
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };
                                console.log("curValue ", statePlayer.mathsData[arrayName][index], "newValue ", change, "index", index);
                                statePlayer.mathsData[arrayName][index] = change;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });
                    });

                    room.state.players.onRemove((player, key) => {
                        console.log(player, "has been removed at", key);
                        this.removePlayer(player.id);
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, });
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.mathsData.gameState);
                        if ((this.state.roomState.mathsData.gameState === GameStates.Loading || this.state.roomState.mathsData.gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send("start_round", {});
                        }
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.doStartRound(message);
                    });

                    room.onMessage("show_answers", (message) => {
                        console.log("show_answers", "received on", room.name, message);
                        this.doShowAnswers(message.closestPlayerKey);
                    });

                    room.onMessage("round_ended", (message) => {
                        this.playAudio(audio.RoundOver);
                    });

                    room.onMessage("end_round", (message) => {
                        console.log("end_round", "received on", room.name, message);
                        this.doEndRound();
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.doGameOver();
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false, });
                        this.state.room.send("start_round", {});
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);

                        this.setState({ showTimer: true, });
                        if (message.count <= 5) {
                            this.playAudio(audio.ClockTick);
                            if (message.count === 5) {
                                //this.playAudio(audio.Timer5SecondsStart);
                            }
                            if (message.count <= 0) {
                                this.hideTimer();
                            }
                        }
                        this.setState({ timerText: message.count, });
                    });

                    room.onError((code, message) => {
                        Sentry.captureMessage(`WS Received: onError; message: ${message}`);
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Maths Master, code: ${code} Message: ${JSON.stringify(message)}`);
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Maths Master, Code: ${code}`);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Maths Master.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Maths Master: ${JSON.stringify(e)}`);
                    //LoggingService.logError(message, e);
                    if (!message.includes("has been disposed")) Sentry.captureMessage(`doReconnect Error: ${message}`);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}` })
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={styles.gameContainer}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} alt="logo" />
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players} />
                                }
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={1500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} alt="mute" />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} alt="fullscreen" />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} alt="help" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} alt="logo" />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <div className={styles.startButtonSection}>
                                        <button className={`${styles.mainButton}`} onClick={this.signalStartGame}>
                                            <img className={styles.buttonImg} src={ButtonImg2} alt="button" />
                                            Start Game
                                        </button>
                                        <button className={`${styles.mainButton} ${styles.smaller}`} onClick={this.goToLobby}>
                                            <img className={styles.buttonImg} src={ButtonImg1} alt="button" />
                                            Go To Lobby
                                        </button>
                                        <div className={styles.skipBox}>
                                            <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                            <label for="checkbox">Skip Tutorial</label>
                                        </div>
                                    </div>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 === 0) {
                                                return <Player player={x} left={true} room={this.state.room} />
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 === 1) {
                                                return <Player player={x} room={this.state.room} />
                                            }
                                        })
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                        <button className={`${styles.button} ${styles.alt}`} onClick={this.signalNewGame}>
                                            Play Again
                                        </button>
                                        <button className={`${styles.button}`} onClick={this.goToLobby}>
                                            Return to lobby
                                        </button>
                                    </div>
                                }
                                <div className={`${styles.roundNumberSection}`}>
                                    <div className={`${styles.raysContainer} ${this.state.showRoundNumberRays && styles.show}`}>
                                        <img src={RaysImg} className={`${styles.rays} ${styles.show}`} alt="rays" />
                                    </div>
                                    <div className={`${styles.roundTextContainer} ${this.state.showRoundNumber && styles.show}`}>
                                        <div className={`${styles.roundText}`}>
                                            <p className={`${styles.gradiant}`}>ROUND</p>
                                            {<div className={styles.circle}>
                                                <div className={styles.innerCircle}>
                                                    <p className={styles.number} >{this.state.roundNumber}</p>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.numbersSection}`}>
                                    <div className={`${styles.raysContainer} ${this.state.showNumbersSectionRays && styles.show}`}>
                                        <img src={RaysImg} className={`${styles.rays}`} style={{ bottom: "12.5vh" }} alt="rays" />
                                    </div>
                                    <div className={`${styles.goalSection} ${this.state.showNumberGoal && styles.show}`}>
                                        <img className={styles.goalBg} src={GoalBg} alt="goal-bg" />
                                        <div className={styles.goalText}>{this.state.numberGoal}</div>
                                    </div>
                                    <div className={`${styles.infoBox} ${this.state.showInfoBox && styles.show}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>{ this.state.infoBoxText}</div>
                                    </div>
                                    <div className={`${styles.numbersGroup} ${this.state.showCurrentNumbers && styles.show}`}>
                                        {/*<img className={styles.numbersBg} src={NumbersBg} alt="numbers-bg" />*/}
                                        <div className={styles.numbersRow}>
                                            {
                                                this.state.currentNumbers.map((x, i) => {
                                                    return <div className={`${styles.number} ${this.state.showNumbers[i] && styles.show}`}>
                                                        <p className={ styles.numberText} >{x}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.closestAnswerSection} ${this.state.closestPlayerKey && styles.show}`}>
                                    {this.state.closestPlayerKey && this.showClosestAnswer()}
                                </div>
                                <div className={`${styles.winnerSection} `}>
                                    <img src={WinnersTitle} className={`${styles.winnersTitle} ${this.state.showWinnerTitle && styles.show}`} alt="winner-title"/>
                                    <div className={`${styles.raysContainer} ${this.state.showWinnerRays && styles.show}`}>
                                        <img src={RaysImg} className={styles.rays} alt="rays"/>
                                    </div>
                                    <div className={styles.winnerList}>
                                        {
                                            this.getWinners().map((x, index, arr) => {
                                                let size = [1, 2].includes(arr.length) ? "large" : [3, 4].includes(arr.length) ? "medium" : "small";
                                                return <div className={`${styles.winner} ${this.state.showWinners[x.id] && styles.show} ${size === "large" ? styles.large : size === "medium" ? styles.medium : styles.small}`}>
                                                    <div className={`${styles.potato}`}>
                                                        <div className={`${styles.nameBox}`}>
                                                            <img className={styles.winnerOverlay} src={WinnerBanner} alt="winner-banner" />
                                                            <div className={`${styles.name}`}>
                                                                {x.name}
                                                            </div>
                                                        </div>
                                                        <Lottie
                                                            options={getAvatarById(x.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.gameOverSection}`}>

                                    <div className={`${styles.leaderboardSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        <div className={`${styles.tableRow} ${styles.header}`}>
                                            <div className={styles.position}>position</div>
                                            <div className={styles.name}>player</div>
                                            <div className={styles.score}>score</div>
                                        </div>
                                        {
                                            this.getSortedPlayersByScore()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <img src={ClockBg} className={styles.clockBg} alt="clock-bg" />
                                    <div className={styles.clock}>
                                        <img src={ClockImg} className={styles.clockImg} alt="clock" />
                                        <img src={ClockHandImg} className={styles.clockHand} alt="clock-hand" />
                                    </div>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                </div>

                                {/*<div style={{ position: "absolute", zIndex: 10000 }}>*/}
                                {/*    <button onClick={() => this.setState({ showTutorial: true, })}>showTutorial</button>*/}
                                    {/*<button onClick={() => this.doShowAnswers("1")}>doShowAnswers</button>*/}
                                    {/*<button onClick={() => this.doEndRound()}>doEndRound</button>*/}
                                    {/*<button onClick={() => this.setState({ showRoundNumber: !this.state.showRoundNumber })}>ShowRoundNumber</button>*/}
                                    {/*<button onClick={() => this.setState({ closestPlayerKey: "1" })}>ShowClosestPlayer</button>*/}
                                    {/*<button onClick={() => this.setState({ hideClosesPlayer: !this.state.hideClosesPlayer })}>HideClosestPlayer { `${this.state.hideClosesPlayer}`}</button>*/}
                                {/*</div>*/}
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}