import React, { Component } from 'react';
import { Route } from 'react-router';
import * as Sentry from "@sentry/react";
import LoggingService from "services/logging";

import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";

import Loading from "components/Loading";
import DefaultView from "components/AnagramMagic/DefaultView";

import styles from './AnagramClientStyles.module.scss';

import WordTileImg from "images/AnagramMagic/word-tile.png";
import GoldWordTileImg from "images/AnagramMagic/word-tile-gold.png";
import ButtonImg2 from "images/AnagramMagic/button-2.png";
import ClockImg from "images/MathsMaster/clock.png";
import ClockHandImg from "images/MathsMaster/clock-hand.png";
import ClockBg from "images/MathsMaster/clock-bg.png";
import ClientMenuIcon from '../ClientMenuIcon';


var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    SelectingLetters: "selecting_letters",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "anagram_magic";
const bonusLetters = /[QVWXYZ]/g

export class AnagramClient extends Component {
    static displayName = AnagramClient.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            hostConnected: false,
            players: [],
            logStreamId: "",

            player: null,
            doingTutorial: false,

            showLetterView: false,

            showAnswerView: false,
            showPostAnswerView: false,
            currentAnagram: "",
            roundType: "",
            vowelsSelected: 0,
            consonantsSelected: 0,
            currentAnswer: "",
            selectedIndexes: [],

            timerText: 0,
            //showTimer: false,
        };
        this.locationCheckInterval = null;
    }

    componentDidMount() {
        this.setTags();
        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // update this with your gamestates, if player joins/reconnects mid-game
    doCatchup(state) {
        const player = state.players[this.state.myId];
        switch (state.anagramData.gameState) {
            case GameStates.Tutorial:
                if (player.votedSkip === false) {
                    this.setState({ doingTutorial: true });
                }
                break;
            case GameStates.SelectingLetters:
                if (state.anagramData.playerSelectingId === this.state.myId) {
                    this.setState({ showLetterView: true, });
                }
                break;
            case GameStates.Playing:
                //this.state.room.sed("request_question", {});
                if (!player.anagramData.ready) {
                    this.setState({ showAnswerView: true, currentAnagram: state.anagramData.currentAnagram, showPostAnswerView: false, })
                } else {
                    this.setState({ showAnswerView: false, showPostAnswerView: true, });
                }
                break;
            case GameStates.Idle:
                this.setState({ showAnswerView: false, doingTutorial: false, showLetterView: false, showPostAnswerView: false, });
                this.resetRoundVals();
                break;
            default:
                break;
        }
    }

    resetRoundVals() {
        this.setState({
            //currentAnagram: "",
            currentAnswer: "",
            selectedIndexes: [],
        });
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    shuffle(string) {
        return string.split('')
            .sort(() => 0.5 - Math.random())
            .join('');
    }

    shouldShowDefaultView() {
        return !this.state.showAnswerView
            && !this.state.doingTutorial
            && !this.state.showLetterView
            && !this.state.showPostAnswerView;
    }

    selectNewLetter(type) {
        if (type === "vowel") this.setState({ vowelsSelected: this.state.vowelsSelected + 1 });
        else this.setState({ consonantsSelected: this.state.consonantsSelected + 1 });
        this.state.room.send("select_letter", { selectedType: type });
    }

    checkCanVowel() {
        return (9 - this.state.consonantsSelected - this.state.vowelsSelected) <= (4 - this.state.consonantsSelected);
    }

    checkCanConsonant() {
        return (9 - this.state.consonantsSelected - this.state.vowelsSelected) <= (3 - this.state.vowelsSelected);
    }

    clickLetter(letter, selectedIndex, isAdd) {
        let selectedIndexes = this.state.selectedIndexes;
        let currentAnswer = this.state.currentAnswer;
        console.log("Selected Index : ", selectedIndex);

        if (isAdd) {
            if (selectedIndexes.includes(selectedIndex)) {
                let index = selectedIndexes.indexOf(selectedIndex);
                selectedIndexes.splice(index, 1);
                currentAnswer = currentAnswer.slice(0, index) + currentAnswer.slice(index + 1);
            } else {
                selectedIndexes.push(selectedIndex);
                currentAnswer += letter;
            }
        } else {
            selectedIndexes.splice(selectedIndex, 1);
            currentAnswer = currentAnswer.slice(0, selectedIndex) + currentAnswer.slice(selectedIndex + 1);
        }

        console.log("Selected Indexes : ", selectedIndexes);
        console.log("Current Answer : ", currentAnswer);
        this.setState({ selectedIndexes: selectedIndexes, currentAnswer: currentAnswer, });

        this.state.room.send("update_answer", { answer: currentAnswer });
    }

    submitAnswer = () => {
        const currentAnswer = this.state.currentAnswer;
        if (this.state.roundType === "standard") {
            if (currentAnswer.length >= 3) {
                this.state.room.send("anagram_submit", { answer: currentAnswer });
                this.setState({ showAnswerView: false, showPostAnswerView: true, });
            }
        } else {
            if (currentAnswer.length >= 9) {
                this.state.room.send("anagram_bonus_submit", { answer: currentAnswer });
                this.setState({ showAnswerView: false, showPostAnswerView: true, });
            }
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            console.log("Adding player to bonus actions : ", player.id);
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    unready = () => {
        this.state.room.send("anagram_unready");
        this.setState({ showPostAnswerView: false, showAnswerView: true });
    }

    doReconnect = () => {
        // fetch room and session id from query params
        //const roomId = this.getQueryStringValue("roomId");
        //const sessionId = this.getQueryStringValue("sessionId");
        const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.state.players.onAdd((player) => {
                this.checkAndAddPlayer(player);
                player.listen("connected", (currentValue, previousValue) => {
                    let statePlayers = [...this.state.players];
                    let index = statePlayers.findIndex(elem => elem.id === player.id);
                    statePlayers[index].connected = currentValue;
                    this.setState({ players: statePlayers });
                });
            });

            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                this.setState({ roomState: state, player: state.players[this.state.myId], isPaused: state.isPaused, logStreamId: state.uniqueId });
                LoggingService.streamLog(state.uniqueId, `Player ${this.state.myId} ${player.name} Reconnected to Anagram Magic, Reconnection Token: ${room.reconnectionToken}`);

                this.startLocationChecks();
                this.doCatchup(state);
            });

            room.state.anagramData.listen("vowelsSelected", (value) => {
                console.log("new vowels selected : ", value);
                this.setState({ vowelsSelected: value });
            });

            room.state.anagramData.listen("consonantsSelected", (value) => {
                console.log("new consonants selected : ", value);
                this.setState({ consonantsSelected: value });
            });

            room.state.anagramData.listen("roundType", (value) => {
                console.log("roundType chenged to ", value);
                this.setState({ roundType: value });
            });

            room.state.anagramData.listen("currentAnagram", (value) => {
                console.log("currentAnagram chenged to ", value);
                this.setState({ currentAnagram: value });
            });

            room.onStateChange((state) => {
                console.log("room has new state:", state);
                this.setState({ roomState: state });
                this.doCatchup(state);
            });

            room.onMessage("update_timer", (message) => {
                console.log("update_timer", "received on", room.name, message);
                this.setState({ timerText: message.count });
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: true });
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: false });
            });
            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });

            // your game message handlers here
            room.onMessage("start_answering", (message) => {
                console.log("start_answering", "received on", room.name, message);
                this.setState({ showAnswerView: true, currentAnagram: message.anagram, showPostAnswerView: false, });
            });

            room.onMessage("bonus_round_started", (message) => {
                console.log("bonus_round_started", "received on", room.name, message);
                this.setState({ showAnswerView: true, showPostAnswerView: false, });
                this.resetRoundVals();
            });

            room.onMessage("round_started", (message) => {
                console.log("round_started", "received on", room.name, message);
                this.resetRoundVals();
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId !== gameId) {
                    this.goToLobby();
                }
            });

            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });

            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onError((code, message) => {
                Sentry.captureMessage(`WS Received: onError; message: ${message}`);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnError at Anagram Magic, code: ${code} Message: ${JSON.stringify(message)}`);
                console.log(this.client.id, "couldn't join", room.name);
            });

            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} Left Anagram Magic, Code: ${code}`);

                if (!this.state.redirectURL) {
                    if (code === 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });

        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            const message = e.message ? e.message : "An error occured joining Anagram Magic.";
            if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnJoinError at Anagram Magic: ${JSON.stringify(e)}`);
            if (!message.includes("has been disposed")) Sentry.captureMessage(`doReconnect Error: ${message}`);
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={styles.clientContainer}>
                            {
                                this.state.player?.primaryPlayer &&
                                <ClientMenuIcon room={this.state.room} />
                            }
                            {
                                this.shouldShowDefaultView() &&
                                <DefaultView room={this.state.room} player={this.state.player} hostConnected={this.state.hostConnected} players={this.state.players} />
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.state.showLetterView &&
                                <div className={styles.letterSection}>
                                    <div className={`${styles.infoBox}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>Choose your letters!</div>
                                    </div>
                                    <button className={styles.bigButton} onClick={() => this.selectNewLetter("vowel")} disabled={this.checkCanVowel()} >VOWEL</button>
                                    <button className={styles.bigButton} onClick={() => this.selectNewLetter("consonant")} disabled={this.checkCanConsonant()} >CONSONANT</button>
                                </div>
                            }
                            {
                                this.state.showAnswerView &&
                                <div className={styles.answerSection}>
                                    <div className={`${styles.infoBox}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>{this.state.roundType === "standard" ? "Find the longest word!" : "Find the 9 letter word!"}</div>
                                    </div>
                                    <div className={styles.anagram}>
                                        {
                                            this.state.currentAnagram && this.state.currentAnagram.length > 0 && this.state.currentAnagram.split('').map((x, index) => {
                                                let isSelected = this.state.selectedIndexes.filter((x) => x === index).length > 0;
                                                return <React.Fragment>
                                                    {
                                                        index === 4 &&
                                                        <div className="break"></div>
                                                    }
                                                    <div className={`${styles.anagramLetter} ${isSelected && styles.fade}`} onClick={() => this.clickLetter(x, index, true)} key={index}>
                                                        <img src={bonusLetters.test(x) ? GoldWordTileImg : WordTileImg} className={styles.wordTile} alt={`letter-${x}`} />
                                                        <div className={styles.letter}>{x}</div>
                                                    </div>
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                    <div className={styles.answerLetters}>
                                        <div className={styles.background} />
                                        {
                                            this.state.currentAnswer.split('').map((x, index) => {
                                                return <div className={`${styles.answerLetter}`} onClick={() => this.clickLetter(x, index, false)}>
                                                    {/*<img src={WordTileImg} className={styles.wordTile} alt={`letter-${x}`} />*/}
                                                    <div className={styles.letter}>{x}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <button disabled={this.state.roundType === "standard" ? this.state.currentAnswer.length < 3 : this.state.currentAnswer.length < 9} className={`${styles.button}`} onClick={this.submitAnswer}>
                                        <img className={styles.buttonImg} src={ButtonImg2} alt="submit button" />
                                        Submit
                                    </button>
                                </div>
                            }
                            {
                                this.state.showPostAnswerView &&
                                <div className={styles.letterSection}>
                                    <div className={`${styles.infoBox}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>You Submitted:</div>
                                    </div>
                                    <div className={styles.bigButton}>{this.state.player.anagramData.answer}</div>
                                    <button className={`${styles.button}`} onClick={this.unready}>
                                        <img className={styles.buttonImg} src={ButtonImg2} alt="unready-button" />
                                        Back
                                    </button>
                                </div>
                            }
                            {
                                <div className={`${styles.timerSection} ${(this.state.showAnswerView || this.state.showLetterView || this.state.showPostAnswerView) && styles.show}`}>
                                    <img src={ClockBg} className={styles.clockBg} alt="clock-bg" />
                                    <div className={styles.clock}>
                                        <img src={ClockImg} className={styles.clockImg} alt="clock" />
                                        <img src={ClockHandImg} className={styles.clockHand} alt="clock-hand" />
                                    </div>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                </div>
                            }
                            {
                                this.state.doingTutorial &&
                                <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                    <div className={styles.skipButton}>Skip Tutorial</div>
                                </div>
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
